import React from "react";
import LazyLoad from "react-lazyload";
import { graphql, Link } from "gatsby";
import { withContext } from "~context";
import { BreadCrumbs } from "~components/assessment/bread-crumbs";
import { AssessmentVoiceList } from "~components/assessment/voice-list";
import { Assessment2PaneLayout } from "~components/templates/assessment-2pane-layout/page";
import "./index.scss";
import { PriceDocBanner } from "~components/assessment/price_doc_banner";
import { AssessmentDocContact } from "~components/assessment/price_doc_contact";
import { DocumentStyle } from "~components/templates/document-style";
import { Common2PaneSectionLayout } from "~components/templates/common-2pane-layout/section-layout";
import { Section } from "~components/templates/section";
import { TableStyle } from "~components/templates/table-style";
import { Interview } from "~components/utils/interview";

type Props = {
  data: GatsbyGraphqlPageQuery;
};

const Component: React.FC<Props> = (props) => {
  const { data } = props;
  const CONTACT_FORM_ID =
    "31lfBkuItiC6zUlXxuv94ntAG0HPusBDbhRIM9Ck5f5X7VEJlguDsXpREs7pipVn5";

  return (
    <>
      <PriceDocBanner />
      <Assessment2PaneLayout
        formId={CONTACT_FORM_ID}
        meta={{
          title: "GraphQL診断",
          description:
            "Webアプリケーションのセキュリティ診断観点に、GraphQL利用時に起こりうる特有の観点を加え、専門のセキュリティエンジニアが診断します。",
        }}
      >
        <div className="Graphql AssessmentCommonPage">
          <LazyLoad>
            <div className="AssessmentCommonTop GraphqlTop">
              <div className="AssessmentCommonTop_Wrapper">
                <Common2PaneSectionLayout>
                  <DocumentStyle width="no-limit">
                    <h1>GraphQL診断</h1>
                    <p>
                      Webアプリケーションのセキュリティ診断観点に、GraphQL利用時に起こりうる特有の観点を加え、専門のセキュリティエンジニアが診断します。
                    </p>
                  </DocumentStyle>
                </Common2PaneSectionLayout>
              </div>
            </div>
          </LazyLoad>

          <BreadCrumbs title="GraphQL診断" />

          <Section background="subtle-gray">
            <Common2PaneSectionLayout>
              <DocumentStyle>
                <h2>GraphQLを用いたアプリケーションのセキュリティリスク</h2>
                <p>
                  GraphQLを用いたWeb
                  APIのセキュリティを確保するためには、Insecure Direct Object
                  ReferenceといったWeb
                  APIにおいて顕在化しやすい脆弱性だけではなく、Introspection
                  Queryによるスキーマ情報の流出や不正に細工されたクエリによって発生するDoS(Denial
                  of Service)など、GraphQL
                  API固有の脆弱性が存在しないことを確認する必要があります。
                  <br />
                  <br />
                  これらの脆弱性に関する正確な診断を行うためには、従来のWeb API
                  (いわゆる REST API)
                  のそれとは大きく異なる、GraphQL固有のコンフィグレーションや実装をホワイトボックス的に精査する必要があります。そのため、本診断を行う診断員にはGraphQL
                  APIのエコシステムとWeb
                  APIセキュリティの双方に深い知見を有していることが求められます。
                </p>
              </DocumentStyle>
            </Common2PaneSectionLayout>
          </Section>

          <LazyLoad>
            <Section>
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>
                    GraphQL APIを持つWebアプリケーションに対するセキュリティ診断
                  </h2>
                  <img src={data?.api?.publicURL || ""} alt="" />
                  <p>
                    REST
                    APIではエンドポイントごとに機能が割り当てられ、それに対応したWeb標準のパラメータ形式を利用してリクエストを行います。GraphQLでは単一のエンドポイントにスキーマファイルで定義されたQueryやMutationといった専用の形式でリクエストを送信するため、GraphQL特有の挙動に対する理解が必要です。
                  </p>
                  <h3>提供をご依頼するデータ</h3>
                  <p>
                    GraphQLを用いたWeb APIの診断を実施する際には、当該GraphQL
                    APIが扱うデータの型やクエリの種類が定義されたスキーマファイルの提供をご依頼します。
                    より詳細な診断をご希望される際には、上記に加えてGraphQLのクエリを実際に処理するResolverの実装やバックエンドのデータベース等との連携を含めたシステム構成図をご提供いただきます。
                    これらの内容を専門の診断員が精査することで、診断対象のGraphQL
                    APIに脆弱性が存在しないかを診断します。
                  </p>
                  <h3>GraphQL診断のユースケース</h3>
                  <ul>
                    <li>GraphQL診断単体でのご利用</li>
                    <li>
                      REST APIが存在する場合、REST APIに対する
                      <Link to="/assessment/web_app">
                        Webアプリケーション診断
                      </Link>
                      と組み合わせて実施することも可能
                    </li>
                    <li>
                      その他、
                      <Link to="/assessment/platform">
                        プラットフォーム診断
                      </Link>
                      や
                      <Link to="/assessment/sp_app">
                        スマートフォンアプリ診断
                      </Link>
                      、
                      <Link to="/assessment/cloud_platform">
                        AWS・Google Cloud・Azure診断
                      </Link>
                      等と組み合わせることも可能
                    </li>
                  </ul>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>

          <LazyLoad>
            <Section>
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>診断観点</h2>

                  <TableStyle>
                    <h3>設定</h3>
                    <table>
                      <tbody>
                        <tr>
                          <th>Introspection Queryが有効</th>
                          <td>
                            サービスの性質上必要の無いGraphQL
                            Schemaの公開が、Introspection
                            Queryによって行われていないかどうかを確認します。
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <h3>認可</h3>
                    <table>
                      <tbody>
                        <tr>
                          <th>権限昇格</th>
                          <td>
                            クエリ内で用いられているパラメータを変更することで、管理者用機能のような権限を所有していないと操作できない機能において、一般ユーザの権限で実行できるような脆弱性がないか検査します。
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table>
                      <tbody>
                        <tr>
                          <th>認可制御の不備</th>
                          <td>
                            クエリ内で用いられているパラメータを変更することで、情報の所有者のみアクセスできることを想定しているような機能において、権限を持たないユーザが情報にアクセスできるような脆弱性がないか検査します。
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <h3>その他</h3>
                    <table>
                      <tbody>
                        <tr>
                          <th>クエリを利用したDoS</th>
                          <td>
                            クエリの組み合わせを悪用することでサービスに対するDoSが行えないかどうかを検査します。
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </TableStyle>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>

          <LazyLoad>
            <Interview title="GraphQL診断の事例インタビュー">
              <AssessmentVoiceList slugs={["anique", "kakehashi", "gaudiy"]} />
            </Interview>
          </LazyLoad>
          <LazyLoad>
            <Section background="brand">
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>料金</h2>
                  <h4>
                    リクエスト数に基づいてお見積りを行い料金を決定します。
                  </h4>
                  <p>
                    GraphQL診断ではリクエスト数×リクエスト単価で料金が計算可能です。ご予算に応じて診断対象のエンドポイントを優先度の高いもののみに絞ることも可能です。ぜひご相談ください。
                  </p>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>

          <AssessmentDocContact />

          {/* <LazyLoad>
            <Section>
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>技術ブログ公開中</h2>
                  <p>
                    GraphQLを題材にセキュリティに関する技術ブログを公開中です。ぜひご覧ください。
                  </p>
                  <div className="CloudPlatform_BlogItem">
                    <a href="https://flattsecurity.hatenablog.com/entry/cloud_security_aws_case">
                      <img
                        className="CloudPlatform_BlogImage"
                        src="https://cdn-ak.f.st-hatena.com/images/fotolife/f/flattsecurity/20201118/20201118110817.png"
                        alt="技術ブログサムネイル「AWS 診断を事例としたクラウドセキュリティ。サーバーレス環境の不備や見落としがちな Cognito の穴による危険性」"
                      />
                      https://flattsecurity.hatenablog.com/entry/cloud_security_aws_case
                    </a>
                  </div>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad> */}
        </div>
      </Assessment2PaneLayout>
    </>
  );
};

export const query = graphql`
  query GraphqlPage {
    api: file(relativePath: { eq: "assessment/graph_ql_api.png" }) {
      publicURL
    }
  }
`;

export default withContext(Component);
